import { useBreakpoints } from '@vueuse/core'

export function useDeviceSize() {
  const breakpoints = useBreakpoints({
    xxs: 320,
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
  })

  const isXxs = breakpoints.smallerOrEqual('xxs')
  const isXs = breakpoints.smallerOrEqual('xs')
  const isSm = breakpoints.smallerOrEqual('sm')
  const isMd = breakpoints.smallerOrEqual('md')
  const isLg = breakpoints.smallerOrEqual('lg')
  const isXl = breakpoints.smallerOrEqual('xl')
  const isMobile = breakpoints.smallerOrEqual('sm')
  const isTablet = breakpoints.between('md', 'lg')

  const isPc = computed(() => {
    return !!(navigator?.userAgent?.match(/Windows/i) || navigator?.userAgent?.match(/Macintosh/i))?.length
  })

  const isAndroid = computed(() => {
    return !!navigator?.userAgent?.match(/Android/i)?.length
  })

  const isIOS = computed(() => {
    return !!navigator?.userAgent?.match(/iPhone|iPad|iPod/i)?.length
  })

  return {
    isXxs,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isMobile,
    isTablet,
    isPc,
    isAndroid,
    isIOS,
  }
}
